import { useRef } from "react";
import "./App.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Ratio from "react-bootstrap/Ratio";
import { CopyToClipboard } from "react-copy-to-clipboard";

import navlogo from "./assets/images/logo-popbrc.png";
import pbrccoin from "./assets/images/token-pbrc_1.png";
import doge from "./assets/images/character-doge-1_1.png";
import pepe from "./assets/images/character-pepe-1_1.png";
import shiba from "./assets/images/character-shiba-1_1.png";
import gpoverlay from "./assets/images/bg-overlay_1.png";
import btc from "./assets/images/token-btc_1.png";
import coin from "./assets/images/img-coin.png";
import bridge from "./assets/images/img-bridge-min.png";
import frame1 from "./assets/images/img-frame_1.png";
import frame2 from "./assets/images/img-frame_2.png";
import frame3 from "./assets/images/img-frame_3.png";
import inhouse from "./assets/images/img-bridge.png";
import user from "./assets/images/img-user.png";
import dot from "./assets/images/dot.svg";
import chart from "./assets/images/img-chart.png";
import dot1 from "./assets/images/dot-1.png";
import dot2 from "./assets/images/dot-2.png";
import dot3 from "./assets/images/dot-3.png";
import dot4 from "./assets/images/dot-4.png";
import dot5 from "./assets/images/dot-5.png";
import dot6 from "./assets/images/dot-6.png";
import titlephase from "./assets/images/bg-title-phase.png";
import sog from "./assets/images/avatar-Satoshi.png";
import tele from "./assets/images/ic-telegram_1.svg";
import x from "./assets/images/ic-twitter_1.svg";
import discord from "./assets/images/ic-discord_1.svg";
import footer from "./assets/images/img-footer.png";
import copy from "./assets/images/ic-copy.png";
import phase1 from "./assets/images/bg-img-phase_1.png";
import phase2 from "./assets/images/bg-img-phase_2.png";
import phase3 from "./assets/images/bg-img-phase_3.png";
import phase4 from "./assets/images/bg-img-phase_4.png";
import playbtn from "./assets/images/btn-play.png";
import icetea from "./assets/images/icetealab.webp";
import spark from "./assets/images/spark.png";
import moon from "./assets/images/moonrock.png";
import msv from "./assets/images/msvgg.png";
import basic from "./assets/images/basiccapital.png";
import onemax from "./assets/images/onemax.png";
import banter from "./assets/images/cryptobanter.png";
import tcvn from "./assets/images/tradecoinvn.png";
import hg from "./assets/images/hgventures.png";
import moonboots from "./assets/images/moonboots.png";
import zbs from "./assets/images/zbs.png";
import chappyz from "./assets/images/chappyz.png";
import hold from "./assets/images/holdstation.png";
import eese from "./assets/images/essee.png";
import world from "./assets/images/worldgames.png";
import poptele from "./assets/images/gametele.png";
import popx from "./assets/images/gamex.png";

import useWindowDimensions from "./hook";

function App() {
  const ref = useRef(null);
  const toggle = useRef(null);
  const wref = useRef(null);
  const { width } = useWindowDimensions();

  const handleClick = (event) => {
    if (event.currentTarget.classList.contains("collapsed") & (width < 768)) {
      ref.current.classList.remove("d-none");
    } else {
      ref.current.classList.add("d-none");
    }
  };
  const toggleNav = () => {
    toggle.current.click();
  };
  const selectHandler = () => {
    toggleNav();
  };
  const specialHandler = (event) => {
    event.stopPropagation();
    wref.current.classList.remove("active");
  };
  return (
    <div className="pop-brc position-relative">
      <div className="announce position-absolute">
        <div className="d-flex align-items-center justify-content-center">
          <img src={dot} alt="dot" />
          <p>PECLAND has rebranded and pivotted into POPBRC</p>
          <img src={dot} alt="dot" />
        </div>
      </div>
      <div
        ref={ref}
        onClick={toggleNav}
        className="pop-brc-overlay d-none"
      ></div>
      <Navbar onSelect={selectHandler} expand="lg" className="navbar-custom">
        <Container fluid className="mobile-nav">
          <Navbar.Brand
            href="#home"
            className="d-none d-lg-block text-center mx-auto p-0"
          >
            <img
              src={navlogo}
              alt="logo"
              className="d-block"
              style={{ width: "120px", height: "60px", objectFit: "contain" }}
            />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className="custom-toggler"
            onClick={handleClick}
            ref={toggle}
          >
            <span className="my-1 mx-2 close" style={{ color: "white" }}>
              X
            </span>
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav
              variant="underline"
              className="justify-content-between align-items-lg-center align-items-start pt-4 pb-3 w-100"
            >
              <div className="d-flex flex-column flex-lg-row">
                <Nav.Link href="#popbrc">
                  <span className="nav-text">$PECL</span>
                </Nav.Link>
                <Nav.Link href="#gameplay">
                  <span className="nav-text">Gameplay</span>
                </Nav.Link>
                <Nav.Link href="#proof">
                  <span className="nav-text">Proof of Play</span>
                </Nav.Link>
                <Nav.Link href="#video">
                  <span className="nav-text">Video</span>
                </Nav.Link>
                <Nav.Link href="#tokenomics">
                  <span className="nav-text">Tokenomics</span>
                </Nav.Link>
                <Nav.Link href="#roadmap">
                  <span className="nav-text">Roadmap</span>
                </Nav.Link>
                <Nav.Link
                  href="https://popbrc.gitbook.io/home/"
                  target="_blank"
                  rel="noreferrer"
                  onClick={specialHandler}
                  ref={wref}
                >
                  <span className="nav-text">Whitepaper</span>
                </Nav.Link>
                <Nav.Link href="#growthhacking">
                  <span className="nav-text">Growth Hacking</span>
                </Nav.Link>
              </div>

              <Nav.Link
                href="https://pancakeswap.finance/swap?outputCurrency=0x78353861e96a4A3D757BC7EF6C0dd4F667e6274B"
                target="_blank"
                rel="noreferrer"
              >
                <div className="d-block mb-0 buy-btn">
                  <p className="mb-0">
                    BUY <span className="text">$PECL</span> ON PANCAKE
                  </p>
                </div>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* FIRST SECTION */}
      <div className="first-section" id="home">
        <div className="pattern-section">
          <Container className="position-relative" fluid="lg">
            <img
              src={navlogo}
              width={200}
              alt=""
              className="mx-auto d-block d-lg-none"
            />
            <h1 className="text-center">
              THE 1ST “PROOF OF PLAY” GAME ON THE{" "}
              <span className="text-yellow">BRC-20 PROTOCOL</span>
            </h1>
            <div className="play-button text-center mx-auto d-none d-lg-block">
              <img src={playbtn} alt="playbtn" className="d-block w-100" />
              <a
                href="https://play.popbrc.com/"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <span>Play Mainnet</span>
              </a>
            </div>
            <div className="address add-2 mx-auto mb-2 mt-4 position-relative">
              <div className="mb-0">
                <span className="text-green">BEP-20:</span>
                <br />
                <p style={{ wordWrap: "break-word" }} className="mb-0">
                  0x78353861e96a4a3d757bc7ef6c0dd4f667e6274b
                </p>
              </div>
              <CopyToClipboard
                className="position-absolute copy-position fx-self"
                style={{ cursor: "pointer", width: "21px" }}
                text="0x78353861e96a4a3d757bc7ef6c0dd4f667e6274b"
              >
                <img src={copy} alt="" />
              </CopyToClipboard>
            </div>
            <div className="address add-2 mx-auto position-relative">
              <div className="mb-0">
                <span className="text-green">BRC-20:</span>
                <br />
                <p style={{ wordWrap: "break-word" }} className="mb-0">
                  a63aa59f65085745cf127c91f7bfc4b933c7decd49c32a6f001b3c29f21cfb7ci0
                </p>
              </div>
              <CopyToClipboard
                className="position-absolute copy-position fx-self"
                style={{ cursor: "pointer", width: "21px" }}
                text="a63aa59f65085745cf127c91f7bfc4b933c7decd49c32a6f001b3c29f21cfb7ci0"
              >
                <img src={copy} alt="" />
              </CopyToClipboard>
            </div>
            <a
              href="https://pancakeswap.finance/swap?outputCurrency=0x78353861e96a4A3D757BC7EF6C0dd4F667e6274B"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <div className="d-block mobile-buy mb-0 buy-btn d-block d-md-none">
                BUY <span className="text">$PECL</span> ON PANCAKE
              </div>
            </a>
          </Container>
        </div>
      </div>

      {/* SECOND SECTION */}
      <div className="second-section" id="popbrc">
        <Container className="position-relative content">
          <div className="row align-items-center upper">
            <div className="col-12 col-lg-6">
              <h3 className="text-green-title text-underline position-relative">
                $PECL
              </h3>
              <div className="pe-5">
                <div className="d-flex align-items-baseline">
                  <img src={dot} alt="dot" />
                  <p className="ms-4 mb-4">
                    POPBRC is the GameFi platform for the Bitcoin ecosystem. It
                    maps Bitcoin assets (such as BRC20, and others) to BSC and
                    other Layer2 networks. <br /> POPBRC introduces new elements
                    to Bitcoin assets, including but not limited to Play2Earn.
                    <br />
                    The mission of POPBRC is to build the next-generation gaming
                    ecosystem across Bitcoin and EVM networks, uniting the
                    communities of both sides
                  </p>
                </div>
                <div className="d-flex align-items-baseline">
                  <img src={dot} alt="dot" />
                  <p className="ms-4 mb-0">
                    $PECL is the native token of POPBRC Gaming Ecosystem. It is
                    originally inscribed on Bitcoin and part of it is bridged /
                    wrapped to BSC (BEP-20) to benefit from the well developed
                    DeFi and GameFi infrastructure.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <img
                src={pbrccoin}
                alt="pbrc"
                className="d-block mx-auto mb-4 coin-img"
              ></img>
              <div>
                <div className="d-flex justify-content-between pbrc-border mb-1">
                  <p>Name: </p>
                  <p style={{ fontWeight: "400" }} className="text-green">
                    PECLAND
                  </p>
                </div>
                <div className="d-flex justify-content-between pbrc-border mb-1">
                  <p>Ticker: </p>
                  <p style={{ fontWeight: "400" }} className="text-yellow">
                    $PECL
                  </p>
                </div>
                <div className="d-flex justify-content-between pbrc-border mb-1">
                  <p>Total supply: </p>
                  <p style={{ fontWeight: "400" }} className="text-yellow">
                    50B
                  </p>
                </div>
                <div className="d-flex justify-content-between pbrc-border">
                  <p>Protocol: </p>
                  <p style={{ fontWeight: "400" }} className="text-yellow">
                    BEP-20 and BRC-20
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row" id="gameplay">
            <div className="col-12 col-lg-6">
              <div className="row d-none d-lg-flex pe-0 pe-lg-5">
                <div className="col-6">
                  <div className="d-flex h-100 align-items-center">
                    <div className="character-card animation w-100 doge">
                      <img src={doge} alt="doge" className="d-block w-100" />
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="character-card animation pepe mb-4">
                    <img src={pepe} alt="pepe" className="d-block w-100" />
                  </div>
                  <div className="character-card animation shiba">
                    <img src={shiba} alt="shiba" className="d-block w-100" />
                  </div>
                </div>
              </div>
              <div className="row gx-2 d-lg-none">
                <div className="col-4 mt-4">
                  <div className="character-card w-100 doge">
                    <img src={doge} alt="doge" className="d-block w-100" />
                  </div>
                </div>
                <div className="col-4">
                  <div className="character-card pepe mb-4">
                    <img src={pepe} alt="pepe" className="d-block w-100" />
                  </div>
                </div>
                <div className="col-4 mt-4">
                  <div className="character-card shiba">
                    <img src={shiba} alt="shiba" className="d-block w-100" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="ps-lg-5 gameplay-col">
                <h3 className="text-large text-green-title text-underline position-relative">
                  "POPBRC"
                  <span style={{ color: "white" }}>GAMEPLAY</span>
                </h3>
                <p className="gameplay mt-4">
                  BRINGING ‘PROOF OF PLAY’ TO THE BITCOIN ECOSYSTEM WITH OUR
                  GAME ON <span className="text-yellow">BEP-20</span>
                </p>
                <div className="d-flex align-items-baseline">
                  <img src={dot} alt="" />
                  <p className="mb-4 text-light-custom ps-4">
                    Stake to get NFT, unstake to get all the tokens back
                  </p>
                </div>

                <div className="d-flex align-items-baseline">
                  <img src={dot} alt="" />
                  <p className="mb-4 text-light-custom ps-4">
                    Attack Boss to get rewards
                  </p>
                </div>

                <div className="d-flex align-items-baseline">
                  <img src={dot} alt="" />
                  <p className="mb-5 text-light-custom ps-4">
                    NFTs are the users’ friendly meme characters: Pepe the Frog,
                    Shiba Inu, and Doge
                  </p>
                </div>

                <a
                  href="https://pancakeswap.finance/swap?outputCurrency=0x78353861e96a4A3D757BC7EF6C0dd4F667e6274B"
                  className="mt-3 buy-pbrc d-flex align-items-center"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={pbrccoin}
                    alt="pbrc"
                    width={56}
                    className="d-block"
                  />
                  <p className="mb-0 ms-3">BUY $PECL</p>
                </a>
              </div>
            </div>
          </div>
          <div className="growth-hacking">
            <div className="benefits mx-auto">
              <h3
                id="growthhacking"
                className="text-underline-2 text-pink text-center"
              >
                GROWTH HACKING
              </h3>
            </div>
            <p className="growth">
              Our innovative growth strategy leverages two engaging minigames
              which are integrated on Telegram and X where fierce competition
              unlocks token airdrops, and aim to propel user acquisition and
              foster community engagement
            </p>
            <div
              className="row growth-row gx-5 justify-content-center"
              style={{ marginTop: "136px" }}
            >
              <div className="col-12 col-lg-5 fifth-col">
                <div className="proof-card">
                  <img
                    src={poptele}
                    alt="fr1"
                    className="proof-card-img position-absolute"
                  />
                  <div className="proof-content">
                    <p className="title">POPLAUNCH ON TELEGRAM</p>
                    <p>
                      Where players can easily tap to earn points, invite
                      friends and follow others socials media to get more points
                      and climb the leaderboard to recieve token airdrops.
                    </p>
                    <div>
                      <a
                        href="https://t.me/Poplaunchbot"
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: "none" }}
                      >
                        <div className="play-button text-center mx-auto">
                          <p>Play</p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-5 fifth-col">
                <div className="proof-card">
                  <img
                    src={popx}
                    alt="fr1"
                    className="proof-card-img position-absolute"
                  />
                  <div className="proof-content">
                    <p className="title">POPBRC ON X </p>
                    <p>
                      A social media engagement minigame where users earn
                      rewards by sharing, commenting, and using designated
                      hashtags.
                    </p>
                    <div>
                      <a
                        href="https://chromewebstore.google.com/detail/popbrc/idielocoplbcchckikbhcfjhhnhienjj?hl=en-GB"
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: "none" }}
                      >
                        <div className="play-button text-center mx-auto">
                          <p>Play</p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src={gpoverlay}
            alt="overlay"
            className="position-absolute overlay-1"
          />
          <img
            src={gpoverlay}
            alt="overlay"
            className="position-absolute overlay-2"
          />
          <img
            src={gpoverlay}
            alt="overlay"
            className="position-absolute overlay-3"
          />
        </Container>
      </div>

      {/* THIRD SECTION */}
      <div className="third-section position-relative" id="proof">
        <div className="proof-section">
          <Container>
            <div className="row">
              <div className="col-12 col-lg-7">
                <h3 className="text-yellow-title text-underline position-relative">
                  PROOF OF PLAY
                </h3>
                <p className="position-relative text-yellow-proof text-light-custom">
                  USING THE BTC’S PROOF OF WORK (POW) MECHANISM IN THE POPBRC
                  ECONOMY.
                  <br /> GET READY TO PLAY, EARN, AND BE A PART OF SOMETHING
                  BIG! 🚀🎮
                </p>
                <div className="comparison">
                  <div className="row gx-2 gx-lg-4">
                    <div className="col-6">
                      <div className="coin-col position-relative">
                        <img
                          src={btc}
                          alt="btc"
                          className="position-absolute coin"
                        />
                        <div className="content-card mb-1">
                          <p
                            className="coin-title mb-2 mb-lg-3"
                            style={{ color: "#B2FFD0" }}
                          >
                            DIFFICULT LEVEL
                          </p>
                          <p className="mb-0 coin-content">
                            Miner increase &#8594; <br /> harder to find a block
                          </p>
                        </div>
                        <div className="content-card mb-1">
                          <p
                            className="coin-title mb-2 mb-lg-3"
                            style={{ color: "#77FBED" }}
                          >
                            HALVING PERIOD
                          </p>
                          <p className="mb-0 coin-content">
                            Every <span className="text-yellow">4</span> years
                          </p>
                        </div>
                        <div className="content-card">
                          <p
                            className="coin-title mb-2 mb-lg-3"
                            style={{ color: "#93F3FF" }}
                          >
                            FIXED REWARDS MINED
                          </p>
                          <p className="mb-0 coin-content">
                            <span className="text-yellow">
                              6.25 BTC per block
                            </span>
                            <br />
                            (currently, after 4 times halving)
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="coin-col position-relative">
                        <img
                          src={pbrccoin}
                          alt="pbrc"
                          className="position-absolute coin"
                        />
                        <div className="content-card mb-1">
                          <p
                            className="coin-title mb-2 mb-lg-3"
                            style={{ color: "#B2FFD0" }}
                          >
                            DIFFICULT LEVEL
                          </p>
                          <p className="mb-0 coin-content">
                            User increase &#8594; <br /> lower rewards gained
                          </p>
                        </div>
                        <div className="content-card mb-1">
                          <p
                            className="coin-title mb-2 mb-lg-3"
                            style={{ color: "#77FBED" }}
                          >
                            HALVING PERIOD
                          </p>
                          <p className="mb-0 coin-content">
                            Every <span className="text-yellow">1</span> years
                          </p>
                        </div>
                        <div className="content-card">
                          <p
                            className="coin-title mb-2 mb-lg-3"
                            style={{ color: "#93F3FF" }}
                          >
                            FIXED REWARDS MINED
                          </p>
                          <p className="mb-0 coin-content">
                            <span className="text-yellow">
                              6.894B tokens/year
                            </span>
                            <br />
                            (3.725B for the 1st halving)
                            <br className="d-block d-lg-none" />
                            {/* <br className="d-block d-lg-none" /> */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="mt-4 mb-0 ms-3 coin-content">
                    Proof of Play (PoL) / Proof of Work(PoW) Comparision
                  </p>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>

      {/* FOURTH SECTION */}
      <div className="fourth-section">
        <Container className="position-relative">
          <h3 className="text-underline-2 text-pink text-center">
            THE GAME MECHANICS IN BRC-20
          </h3>
          <div className="mechanics d-none d-lg-block">
            <div className="row gx-2">
              <div className="col-4">
                <div className="w-100 mechanics-pill d-flex align-items-center">
                  <img
                    src={pbrccoin}
                    alt="pbrc"
                    width={120}
                    className="d-block"
                  />
                  <p className="mb-0 ms-3 coin-content">
                    Get the $PECL tokens swap via Pancake Swap BEP-20
                  </p>
                </div>
                <div className="w-100 my-3 mechanics-pill d-flex align-items-center position-relative">
                  <img
                    src={pbrccoin}
                    alt="pbrc"
                    width={120}
                    className="d-block"
                  />
                  <p className="mb-0 ms-3 coin-content">
                    You have BRC-20 tokens
                  </p>
                </div>
                <div className="w-100 my-3 coin-pill d-flex align-items-center">
                  <p className="mb-0 ms-3 coin-content">
                    NFT BURNT AND EARN YOUR VALUE IN BEP-20
                  </p>
                  <img src={coin} alt="pbrc" width={120} className="d-block" />
                </div>
              </div>
              <div className="col-3">
                <div className="pt-4 pb-3">
                  <div className="d-flex text-red red-arrow justify-content-center align-items-center">
                    <img src={pbrccoin} alt="pbrc" width={40} />
                    <p className="ms-1 mb-0">-10 stake (BEP-20)</p>
                  </div>
                  <div className="d-flex mt-5 text-red justify-content-center align-items-center">
                    <img src={pbrccoin} alt="pbrc" width={40} />
                    <p className="ms-1 mb-0">-10 stake (BRC-20)</p>
                  </div>
                  <div className="mt-2 position-relative arrow">
                    <img
                      src={bridge}
                      alt="bridge"
                      className="bridge-img d-block mx-auto"
                    />
                    <p className="position-absolute cex">BRIDGE</p>
                    <div className="pseudo arrow"></div>
                  </div>
                  <div className="mt-4 d-flex position-relative text-yellow yellow-arrow justify-content-center align-items-center">
                    <img src={pbrccoin} alt="pbrc" width={40} />
                    <p className="ms-1 mb-0">+20 unstaked</p>
                  </div>
                  <div className="mt-3 d-flex text-yellow justify-content-center align-items-center">
                    <img src={pbrccoin} alt="pbrc" width={40} />
                    <p className="ms-1 mb-0">+10 fammed</p>
                  </div>
                </div>
              </div>
              <div className="col-5 py-3 px-0 custom-col">
                <div className="mecha-compare overflow-hidden">
                  <div className="row title-row gx-0">
                    <div className="col-6">
                      <p className="compare-title">$PECL(BEP-20)</p>
                    </div>
                    <div className="col-6">
                      <p className="ms-3 compare-title">POPBRC GAME</p>
                    </div>
                  </div>
                  <div className="row content-row h-100 gx-0">
                    <div className="col-6 pe-3">
                      <p>Take ownership of NFT by staking the $PECL tokens</p>
                      <p>Using NFT to play</p>
                    </div>
                    <div className="col-6">
                      <p>Increase stake to upgrade NFT</p>
                      <p>
                        Unstake to get back the $PECL tokens of the NFT and the
                        earned tokens
                      </p>
                      <p>
                        The interest will be divided to all players depends on
                        the ratio of stacked tokens and playing time
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mechanics-mobile d-block d-lg-none">
            <div className="row pill-row gx-2">
              <div className="col-4">
                <div className="w-100 mechanics-pill d-flex flex-column align-items-center">
                  <img
                    src={pbrccoin}
                    alt="pbrc"
                    width={64}
                    className="d-block"
                  />
                  <p className="mb-0 text-justify mt-3 coin-content">
                    Get the $PECL tokens swap via Pancake Swap BEP-20
                  </p>
                </div>
              </div>
              <div className="col-4">
                <div className="w-100 position-relative mechanics-pill d-flex flex-column align-items-center">
                  <img
                    src={pbrccoin}
                    alt="pbrc"
                    width={64}
                    className="d-block"
                  />
                  <p className="mb-0 text-justify mt-3 coin-content">
                    You have BRC-20 tokens
                  </p>
                  <div className="middle-arrow"></div>
                </div>
              </div>
              <div className="col-4">
                <div className="w-100 coin-pill d-flex flex-column align-items-center">
                  <p className="coin-content text-justify">
                    NFT BURNT AND EARN YOUR VALUE IN BEP-20
                  </p>
                  <img src={coin} alt="pbrc" width={64} className="d-block" />
                </div>
              </div>
            </div>
            <div className="row gx-3x">
              <div className="col-4 d-flex justify-content-between">
                <div className="h-100 w-25 position-relative red-arrow-mobile">
                  <div className="d-flex text-red flex-column justify-content-center align-items-center">
                    <img src={pbrccoin} alt="pbrc" width={40} />
                    <p className="ms-1 mb-0">-10 stake (BEP-20)</p>
                  </div>
                </div>
                <div className="h-100 w-25 position-relative">
                  <div className="d-flex text-red flex-column justify-content-center align-items-center">
                    <img src={pbrccoin} alt="pbrc" width={40} />
                    <p className="ms-1 mb-0">-10 stake (BRC-20)</p>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="position-relative">
                  <img
                    src={bridge}
                    alt="bridge"
                    className="bridge-img d-block mx-auto w-100"
                  />
                  <p className="position-absolute cex mb-0">BRIDGE</p>
                  <div className="middle-arrow"></div>
                </div>
              </div>
              <div className="col-4 d-flex justify-content-between">
                <div className="h-100 w-25 position-relative yellow-arrow-mobile">
                  <div className="d-flex text-yellow flex-column justify-content-center align-items-center">
                    <img src={pbrccoin} alt="pbrc" width={40} />
                    <p className="ms-1 mb-0">+20 unstaked</p>
                  </div>
                </div>
                <div className="h-100 w-25 position-relative">
                  <div className="d-flex text-yellow flex-column justify-content-center align-items-center">
                    <img src={pbrccoin} alt="pbrc" width={40} />
                    <p className="ms-1 mb-0">+10 fammed</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="py-3 custom-col">
                  <div className="mecha-compare overflow-hidden">
                    <div className="row title-row gx-0">
                      <div className="col-6">
                        <p className="compare-title">$PECL (BEP-20)</p>
                      </div>
                      <div className="col-6">
                        <p className="ms-3 compare-title">POPBRC GAME</p>
                      </div>
                    </div>
                    <div className="row content-row h-100 gx-0">
                      <div className="col-6 pe-3">
                        <p>Take ownership of NFT by staking the $PECL tokens</p>
                        <p>Using NFT to play</p>
                      </div>
                      <div className="col-6">
                        <p>Increase stake to upgrade NFT</p>
                        <p>
                          Unstake to get back the $PECL tokens of the NFT and
                          the earned tokens
                        </p>
                        <p>
                          The interest will be divided to all players depends on
                          the ratio of stacked tokens and playing time
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>

      {/* FIFHTH SECTION */}
      <div className="fifth-section">
        <div className="fifth-pseudo"></div>
        <Container className="position-relative">
          <div className="benefits mx-auto">
            <h3 className="text-underline-2 text-pink text-center">
              BENEFITS OF THE PROOF OF PLAY METAGAME
            </h3>
          </div>
          <div className="row fifth-row">
            <div className="col-12 col-lg-4 fifth-col">
              <div className="proof-card">
                <img
                  src={frame1}
                  alt="fr1"
                  className="proof-card-img position-absolute"
                />
                <div className="proof-content">
                  <p className="mb-lg-4 mb-2 title">GAMEFI REVOLUTION</p>
                  <p className="mb-0">- The BTC of GameFi</p>
                  <p className="mb-0">- Revolutionary New Concept</p>
                  <p className="mb-0">- Re-defining GameFi Universe</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 fifth-col">
              <div className="proof-card">
                <img
                  src={frame2}
                  alt="fr1"
                  className="proof-card-img position-absolute"
                />
                <div className="proof-content">
                  <p className="mb-lg-4 mb-2 title">NO INFLATION</p>
                  <p className="mb-0">- Fixed reward tokens</p>
                  <p className="mb-0">- More Users, Low Rewards, High price</p>
                  <p className="mb-0">- Less Users, High Rewards, Low price</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 fifth-col">
              <div className="proof-card">
                <img
                  src={frame3}
                  alt="fr3"
                  className="proof-card-img position-absolute"
                />
                <div className="proof-content">
                  <p className="mb-lg-4 mb-2 title">HALVING INTEGRATION</p>
                  <p className="mb-0">- Reward halving mechanism of Bitcoin</p>
                  <p className="mb-0">- Increase the Rarity</p>
                  <p className="mb-0">- Shared responsibility.</p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>

      {/* SIXTH SECTION */}
      <div className="sixth-section position-relative">
        <Container className="position-relative">
          <div className="inhouse mx-auto">
            <h3 className="text-underline-2 text-pink text-center">
              INTEGRATE <span className="text-yellow">BRC-20</span> BRIDGE GAME
            </h3>
          </div>
          <p className="d-none d-lg-block text-center text-inhouse">
            By building the in-house bridge between BRC-20 and BEP-20, It is not
            just adding a feature; It is creating a gateway to a world of
            possibilities for the players and a defining element for POPBRC
            game. “Break the Chain Barrier: Bridge Easily with in-house Bridge”
          </p>
          <div className="d-flex d-lg-none bridge-section flex-column flex-lg-row align-items-center justify-content-between">
            <div className="position-relative">
              <img src={user} alt="use" className="d-block w-100" />
              <div className="inhouse-content px-3">
                <p className="mb-0">USER</p>
                <p className="mb-0">
                  By connecting two major blockchain networks, user can easily
                  bridge between <br /> BRC-20 and BEP-20 tokens
                </p>
              </div>
              <div className="d-none d-lg-block pseudo-user arrow-user arrow-blue">
                <p className="mb-0 position-absolute play">Play</p>
              </div>
              <div className="d-block d-lg-none pseudo-user-mobile blue">
                <p
                  className="mb-0 position-absolute play"
                  style={{ color: "#77FBED" }}
                >
                  Play
                </p>
              </div>
            </div>
            <div className="position-relative integration-section">
              <img src={inhouse} alt="inhouse" className="inhouse-img" />
              <p className="integration">INHOUSE BRIDGE INTEGRATION</p>
              <p className="d-block d-lg-none text-center bridge-inhouse">
                By building the in-house bridge between BRC-20 and BEP-20, It is
                not just adding a feature; It is creating a gateway to a world
                of possibilities for the players and a defining element for
                POPBRC game. “Break the Chain Barrier: Bridge Easily with
                in-house Bridge”
              </p>
              <div className="d-none d-lg-block pseudo-reward arrow-user arrow-yellow">
                <p className="mb-0 position-absolute rewards">Rewards</p>
              </div>
              <div className="d-block d-lg-none pseudo-user-mobile yellow">
                <p
                  className="mb-0 position-absolute rewards"
                  style={{ color: "#FFCF2E" }}
                >
                  Rewards
                </p>
              </div>
            </div>
            <div className="position-relative">
              <img src={user} alt="use" />
              <div className="inhouse-content px-3">
                <p className="mb-0">USER</p>
                <p className="mb-0">
                  By connecting two major blockchain networks, user can easily
                  bridge between <br /> BRC-20 and BEP-20 tokens
                </p>
              </div>
            </div>
          </div>
          <div className="d-none d-lg-block">
            <div className="row align-items-center">
              <div className="col-3">
                <div className="position-relative">
                  <img src={user} alt="use" className="d-block w-100" />
                  <div className="inhouse-content px-3">
                    <p className="mb-0">USER</p>
                    <p className="mb-0">
                      By connecting two major blockchain networks, user can
                      easily bridge between <br /> BRC-20 and BEP-20 tokens
                    </p>
                  </div>
                  <div className="d-none d-lg-block pseudo-user arrow-user arrow-blue">
                    <p className="mb-0 position-absolute play">Play</p>
                  </div>
                  <div className="d-block d-lg-none pseudo-user-mobile blue">
                    <p
                      className="mb-0 position-absolute play"
                      style={{ color: "#77FBED" }}
                    >
                      Play
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="position-relative integration-section">
                  <img
                    src={inhouse}
                    alt="inhouse"
                    className="inhouse-img d-block mx-auto"
                  />
                  <p className="integration">INHOUSE BRIDGE INTEGRATION</p>
                  <p className="d-block d-lg-none text-center bridge-inhouse">
                    By building the in-house bridge between BRC-20 and BEP-20,
                    It is not just adding a feature; It is creating a gateway to
                    a world of possibilities for the players and a defining
                    element for POPBRC game. “Break the Chain Barrier: Bridge
                    Easily with in-house Bridge”
                  </p>
                  <div className="d-none d-lg-block pseudo-reward arrow-user arrow-yellow">
                    <p className="mb-0 position-absolute rewards">Rewards</p>
                  </div>
                  <div className="d-block d-lg-none pseudo-user-mobile yellow">
                    <p
                      className="mb-0 position-absolute rewards"
                      style={{ color: "#FFCF2E" }}
                    >
                      Rewards
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="position-relative">
                  <img src={user} alt="use" className="d-block w-100" />
                  <div className="inhouse-content px-3">
                    <p className="mb-0">USER</p>
                    <p className="mb-0">
                      By connecting two major blockchain networks, user can
                      easily bridge between <br /> BRC-20 and BEP-20 tokens
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>

      {/* SEVENTH SECTION */}
      <div className="seventh-section">
        <Container>
          <div className="text-hub mx-auto">
            <h3 className="text-underline-3 text-orange text-center">
              HUB MEME CO-OP
            </h3>
          </div>
          <p className="text-center hub-text text-orange modify">
            INTRODUCING HUB MEME: THE FIRST-EVER PROOF OF PLAY GAMING HUB MEME
            TOKEN GATEWAY, THAT EMPHASIZES THE FOCUS ON MEME TOKENS AND THEIR
            INTEGRATION INTO THE GAMEFI ECOSYSTEM WITH THE SUPPORT OF BRIDGE!
          </p>
          <div className="d-flex align-items-baseline dot-text mx-auto">
            <img src={dot} alt="dot" />
            <p className="ms-3 mb-4">
              Community members who want to use “own meme” tokens in the PBRC
              ecosystem project send their BRC-20 tokens to the bridge.
            </p>
          </div>
          <div className="d-flex align-items-baseline dot-text mx-auto">
            <img src={dot} alt="dot" />
            <p className="ms-3 mb-4">
              The bridge locks the BRC-20 tokens and mints an equivalent amount
              of BEP-20 “meme tokens” on BSC Chain.
            </p>
          </div>
          <div className="d-flex align-items-baseline dot-text mx-auto">
            <img src={dot} alt="dot" />
            <p className="ms-3 mb-4">
              Stake their “own meme tokens” in a dedicated pool to earn rewards
            </p>
          </div>
          <div className="d-flex align-items-baseline dot-text mx-auto">
            <img src={dot} alt="dot" />
            <p className="ms-3 mb-0">
              Players deposit their BEP-20 “own meme tokens” into the pool,
              earning rewards throught playing game
            </p>
          </div>
          <div className="video-section" id="video">
            <div className="text-hub mx-auto">
              <h3 className="text-underline-3 text-orange text-center">
                VIDEO GAMEPLAY
              </h3>
            </div>
            <Ratio
              aspectRatio="16x9"
              style={{ zIndex: "1" }}
              className="position-relative video mx-auto"
            >
              <iframe
                src="https://www.youtube.com/embed/mYVm1HFNS7k?si=bjn7JzgjckyGzfRD?rel=0"
                title="Popbrc - The first GameFi Proof of Play and BRC20 game on the market"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </Ratio>
          </div>
        </Container>
      </div>

      {/* EIGHTH SECTION */}
      <div className="eighth-section" id="tokenomics">
        <Container>
          <div className="hub-text mx-auto">
            <h3 className="text-underline-3 text-orange text-center">
              $PECL TOKENOMICS
            </h3>
          </div>
          <div className="tokenomics">
            <div className="row">
              <div className="col-12 col-lg-5 position-relative">
                <div className="position-relative">
                  <img src={chart} alt="" className="w-100 d-block" />
                  <div className="position-absolute text-center chart-text">
                    <p className="mb-0">Allocation</p>
                    <p className="mb-0 text-orange">50B</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-7 position-relative">
                <div className="tokenomic-items-wrapper">
                  <p
                    className="mb-0"
                    style={{ gridArea: "short1", color: "#9BD8D9" }}
                  >
                    IDO
                  </p>
                  <img src={dot1} alt="bigdot" className="dot" />
                  <p className="mb-0" style={{ gridArea: "short2" }}>
                    3.3%
                  </p>
                  <p className="mb-0" style={{ gridArea: "short3" }}>
                    100%
                  </p>
                  <p className="mb-0" style={{ gridArea: "long" }}>
                    None
                  </p>
                </div>
                <div className="tokenomic-items-wrapper">
                  <p
                    className="mb-0"
                    style={{ gridArea: "short1", color: "#EE84A2" }}
                  >
                    Alpha Round
                  </p>
                  <img src={dot2} alt="bigdot" className="dot" />
                  <p className="mb-0" style={{ gridArea: "short2" }}>
                    20%
                  </p>
                  <p className="mb-0" style={{ gridArea: "short3" }}>
                    20%
                  </p>
                  <p className="mb-0" style={{ gridArea: "long" }}>
                    Cliff 1 month, block by block in 6 months
                  </p>
                </div>
                <div className="tokenomic-items-wrapper">
                  <p
                    className="mb-0"
                    style={{ gridArea: "short1", color: "#AB99F6" }}
                  >
                    Team & Advisors
                  </p>
                  <img src={dot3} alt="bigdot" className="dot" />
                  <p className="mb-0" style={{ gridArea: "short2" }}>
                    11%
                  </p>
                  <p className="mb-0" style={{ gridArea: "short3" }}>
                    0
                  </p>
                  <p className="mb-0" style={{ gridArea: "long" }}>
                    Cliff 6 months, then quarterly 2 years
                  </p>
                </div>
                <div className="tokenomic-items-wrapper">
                  <p
                    className="mb-0"
                    style={{ gridArea: "short1", color: "#B8F36E" }}
                  >
                    Ingame rewards
                  </p>
                  <img src={dot4} alt="bigdot" className="dot" />
                  <p className="mb-0" style={{ gridArea: "short2" }}>
                    50%
                  </p>
                  <p className="mb-0" style={{ gridArea: "short3" }}>
                    0
                  </p>
                  <p className="mb-0" style={{ gridArea: "long" }}>
                    3 years
                  </p>
                </div>
                <div className="tokenomic-items-wrapper">
                  <p
                    className="mb-0"
                    style={{ gridArea: "short1", color: "#C16EFF" }}
                  >
                    Treasury & Bridge Deposit
                  </p>
                  <img src={dot5} alt="bigdot" className="dot" />
                  <p className="mb-0" style={{ gridArea: "short2" }}>
                    10%
                  </p>
                  <p className="mb-0" style={{ gridArea: "short3" }}>
                    0
                  </p>
                  <p className="mb-0" style={{ gridArea: "long" }}>
                    3 years
                  </p>
                </div>
                <div className="tokenomic-items-wrapper">
                  <p
                    className="mb-0"
                    style={{ gridArea: "short1", color: "#F2B199" }}
                  >
                    Liquidity & Listing
                  </p>
                  <img src={dot6} alt="bigdot" className="dot" />
                  <p className="mb-0" style={{ gridArea: "short2" }}>
                    5.7%
                  </p>
                  <p className="mb-0" style={{ gridArea: "short3" }}>
                    100%
                  </p>
                  <p className="mb-0" style={{ gridArea: "long" }}>
                    None
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>

      {/* NINTH SECTION */}
      <div className="ninth-section" id="roadmap">
        <Container className="position-relative">
          <div className="w-50 mx-auto">
            <h3 className="text-underline-3 text-orange text-center">
              ROADMAP
            </h3>
          </div>
          <div className="d-none d-lg-block">
            <div className="row justify-content-center roadmap-row">
              <div className="col-4">
                <div className="roadmap-card card-1">
                  <img src={titlephase} alt="" className="position-absolute" />
                  <p className="mb-0 position-absolute title">PHASE 1</p>
                  <div className="position-absolute content content-1">
                    <p>
                      - Architecture and technicals forProof of Play (core
                      function, can not chage over project)
                    </p>
                    <p>- Deploy website v1 + socials</p>
                    <p>- VC Funding</p>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="roadmap-card card-2">
                  <img src={titlephase} alt="" className="position-absolute" />
                  <p className="mb-0 position-absolute title">PHASE 2</p>
                  <div className="position-absolute content content-2">
                    <p>- Prelaunch Marketing Campaign</p>
                    <p>- $PECL token Launch in BEP-20, deploy BRC-20 token</p>
                    <p>- IDO</p>
                    <p>- CEX listing</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-4">
                <div className="roadmap-card card-3">
                  <img src={titlephase} alt="" className="position-absolute" />
                  <p className="mb-0 position-absolute title">PHASE 3</p>
                  <div className="position-absolute content content-3">
                    <p>- POPBRC Game Testnet</p>
                    <p>- POPBRC Game Mainnet</p>
                    <p>- Offical Game Launch</p>
                    <p>- Strategic content + social Media Marketing Push</p>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="roadmap-card card-4">
                  <img src={titlephase} alt="" className="position-absolute" />
                  <p className="mb-0 position-absolute title">PHASE 4</p>
                  <div className="position-absolute content content-4">
                    <p>- Bring POPBRC to another chains</p>
                    <p>
                      - In-house Bridge for Break the Chain Barrier: Swap
                      Seamlessly and easily between BRC-20 and BEP-20
                    </p>
                    <p>
                      - Hub for Meme Community: Players deposit their BEP-20
                      “own meme tokens” into the pool, earning rewards throught
                      playing game
                    </p>
                    <p>- PopBRC: Transitioning to Full On-Chain Gaming</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-block d-lg-none mobile-phase">
            <div className="row">
              <div className="col-8">
                <div className="position-relative">
                  <img src={phase1} className="w-100" alt="" />
                  <img
                    src={titlephase}
                    alt=""
                    className="position-absolute mobile-titlephase"
                  />
                  <p className="mb-0 position-absolute title">PHASE 1</p>
                  <div className="position-absolute content content-1">
                    <p>
                      - Architecture and technicals forProof of Play (core
                      function, can not chage over project)
                    </p>
                    <p>- Deploy website v1 + socials</p>
                    <p>- VC Funding</p>
                  </div>
                </div>
              </div>
              <div className="col-8 minus-margin">
                <div className="position-relative">
                  <img src={phase2} className="w-100" alt="" />
                  <img
                    src={titlephase}
                    alt=""
                    className="position-absolute mobile-titlephase"
                  />
                  <p className="mb-0 position-absolute title">PHASE 2</p>
                  <div className="position-absolute content content-2">
                    <p>- Prelaunch Marketing Campaign</p>
                    <p>- $PECL token Launch in BEP-20, deploy BRC-20 token</p>
                    <p>- IDO</p>
                    <p>- CEX listing</p>
                  </div>
                </div>
              </div>
              <div className="col-8 minus-margin">
                <div className="position-relative">
                  <img src={phase3} className="w-100" alt="" />
                  <img
                    src={titlephase}
                    alt=""
                    className="position-absolute mobile-titlephase"
                  />
                  <p className="mb-0 position-absolute title">PHASE 3</p>
                  <div className="position-absolute content content-3">
                    <p>- POPBRC Game Testnet</p>
                    <p>- POPBRC Game Mainnet</p>
                    <p>- Offical Game Launch</p>
                    <p>- Strategic content + social Media Marketing Push</p>
                  </div>
                </div>
              </div>
              <div className="col-8 minus-margin">
                <div className="position-relative">
                  <img src={phase4} className="w-100" alt="" />
                  <img
                    src={titlephase}
                    alt=""
                    className="position-absolute mobile-titlephase"
                  />
                  <p className="mb-0 position-absolute title">PHASE 4</p>
                  <div className="position-absolute content content-4">
                    <p>- Bring POPBRC to another chains</p>
                    <p>
                      - In-house Bridge for Break the Chain Barrier: Swap
                      Seamlessly and easily between BRC-20 and BEP-20
                    </p>
                    <p>
                      - Hub for Meme Community: Players deposit their BEP-20
                      “own meme tokens” into the pool, earning rewards throught
                      playing game
                    </p>
                    <p>- PopBRC: Transitioning to Full On-Chain Gaming</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>

      <div className="eleventh-section" id="backers" style={{display: "none"}}>
        <Container>
          <div className="w-50 mx-auto">
            <h3 className="text-underline-3 text-orange text-center">
              BACKERS
            </h3>
          </div>
          <div className="backers g-3 g-lg-4 row justify-content-center">
            <div className="col-lg-3 col-6">
              <div className="backer-item">
                <img src={spark} alt="spark" />
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="backer-item">
                <img src={moon} alt="moon" />
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="backer-item">
                <img src={msv} alt="msv" />
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="backer-item" id="icetea">
                <img src={icetea} alt="icetea" />
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="backer-item">
                <img src={basic} alt="basic" />
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="backer-item">
                <img src={onemax} alt="om" />
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="backer-item" id="banter">
                <img src={banter} alt="banter" />
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="backer-item" id="tcvn">
                <img src={tcvn} alt="tcvn" />
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="backer-item">
                <img src={hg} alt="hg" />
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="backer-item">
                <img src={moonboots} alt="boots" />
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="backer-item" id="zbs">
                <img src={zbs} alt="zbs" />
              </div>
            </div>
          </div>
        </Container>
      </div>

      <div className="twelfth-section" id="partners" style={{display: "none"}}>
        <Container>
          <div className="w-50 mx-auto">
            <h3 className="text-underline-3 text-orange text-center">
              PARTNERS
            </h3>
          </div>
          <div className="backers g-3 g-lg-4 row justify-content-center">
            <div className="col-lg-3 col-6">
              <div className="backer-item">
                <img src={chappyz} alt="chappyz" />
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="backer-item">
                <img src={hold} alt="hold" />
              </div>
            </div>

            <div className="col-lg-3 col-6">
              <div className="backer-item">
                <img src={eese} alt="eese" />
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="backer-item" id="wld">
                <img src={world} alt="world" />
              </div>
            </div>
          </div>
          <div className="sog">
            <div className="position-relative">
              <img src={sog} alt="sog" className="sog-img mx-auto d-block" />
              <div className="quote-section">
                <p className="quote">
                  “You can fail at what you don’t love. So you might as well
                  take a chance at failing at what you do love“
                </p>
                <div className="d-flex highlight align-items-center justify-content-between">
                  <p className="mb-0">
                    SOG (Satoshi of gamefi)<span> - CEO</span>
                  </p>
                  <div className="sog-social">
                    <a
                      href="https://t.me/SOGpopbrc"
                      rel="noreferrer"
                      target="_blank"
                      className="fx"
                    >
                      <img src={tele} alt="tele" className="me-3" />
                    </a>
                    <a
                      href="https://twitter.com/SOGpopbrc"
                      rel="noreferrer"
                      target="_blank"
                      className="fx"
                    >
                      <img src={x} alt="x" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="we mx-auto position-relative">
            <h3>WHO WE ARE</h3>
            <div>
              <p>
                🚀 Get ready to level up with POPBRC – where PASSION collides
                with INNOVATION for the ultimate gaming REVOLUTION! We’re not
                just crafting a game; we’re creating a LEGACY, aiming to be the
                BTC of GameFi!
              </p>
              <p className="mb-0">
                Join us on this groundbreaking journey as we shape the future of
                gaming. Welcome to POPBRC – where innovation meets passion!
              </p>
            </div>
          </div>
        </Container>
      </div>

      {/* TENTH SECTION */}
      <div className="tenth-section position-relative">
        <Container>
          {/* <div className="we mx-auto position-relative">
            <h3>WHO WE ARE</h3>
            <div>
              <p>
                🚀 Get ready to level up with POPBRC – where PASSION collides
                with INNOVATION for the ultimate gaming REVOLUTION! We’re not
                just crafting a game; we’re creating a LEGACY, aiming to be the
                BTC of GameFi!
              </p>
              <p className="mb-0">
                Join us on this groundbreaking journey as we shape the future of
                gaming. Welcome to POPBRC – where innovation meets passion!
              </p>
            </div>
          </div> */}
          <img src={footer} alt="f" className="w-100 d-block" />
        </Container>
        <footer className="footer">
          {/* <img src={bgfooter} alt="bgf" className="w-100 d-block" /> */}
          <div className="position-absolute footer-content">
            <div className="d-flex justify-content-between">
              <a
                href="https://twitter.com/PopBRC_official"
                rel="noreferrer"
                target="_blank"
                className="footer-icon fx"
              >
                <img src={x} alt="x" />
              </a>
              <a
                href="https://discord.gg/WadcSPBj6m"
                rel="noreferrer"
                target="_blank"
                className="footer-icon fx"
              >
                <img src={discord} alt="discord" />
              </a>
              <a
                href="https://t.me/POPBRC"
                target="_blank"
                className="footer-icon fx"
                rel="noreferrer"
              >
                <img src={tele} alt="tele" />
              </a>
            </div>
            <p className="mb-0 footer-text">
              © Copyright 2023, All Rights Reserved by POPBRC
            </p>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default App;
